.ws-cancel-plan-discount {
  background: var(--white);

  &__items,
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  &__close-button {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
